import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import { ParserSelection } from "../api/Parsers";
import TableView from "../common/panels/TableView/TableView";
import styles from "./ParserSelection.module.css";
import { Cell, Column, useTable } from "react-table";
import { Table as BTable } from "react-bootstrap";
import { LucideIcon } from "../common/icon/LucideIcon";
import { ToggleButtonComponent } from "../ViewerUIElements/ToggleButtonComponent";

interface TableProps {
  columns: Column<any>[];
  data: any[];
  setSelection: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}

const Table = ({ columns, data }: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<{
    [parserId: string]: ParserSelection;
  }>({
    columns,
    data,
    autoResetSelectedRows: false,
    autoResetSelectedCell: false,
    autoResetSelectedColumn: false,
    autoResetGlobalFilter: false,
    autoResetSortBy: false,
  });

  return (
    <BTable striped bordered hover sizes="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th key={`th-${index}`}>
                <div className={styles.column}>
                  <div className={styles.row}>
                    {column.render("Header")}
                    {index > 0 && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <LucideIcon name="chevron-down" />
                          ) : (
                            <LucideIcon name="chevron-up" />
                          )
                        ) : (
                          <LucideIcon name="code" style={{ transform: "rotate(90deg)", color: "var(--gray-300)" }} />
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              // onClick={() => console.log(row.original)}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export const ParsersSelectionTable = () => {
  const { api } = useContext(SessionContext);

  const [parsers, setParsers] = useState<ParserSelection[]>();

  const selectionSetter = useCallback((selection) => {
    console.log("selectionSetter", selection);
    // const { data } = usePost<[ParserSelection]>("parser_selector/list", {}, {});
  }, []);

  const setValue = useCallback(
    async (parserId, selection) => {
      var result = await api.post(
        "parser_selections/change",
        [{ id: parserId, isDevelopmentVersion: selection }],
        undefined
      );
      setParsers(result.sort((a: ParserSelection, b: ParserSelection) => a.id.localeCompare(b.id)));
    },
    [api]
  );

  useEffect(() => {
    async function fetchData() {
      var result = await api.post("parser_selections/list", {}, undefined);
      setParsers(result.sort((a: ParserSelection, b: ParserSelection) => a.id.localeCompare(b.id)));
    }
    fetchData();
  }, [api]);

  const columns = useMemo(
    () => [
      {
        Header: "Devel active",
        accessor: "isDevelopmentVersion",
        Cell: (cell: Cell) => (
          <div className={styles.row}>
            <ToggleButtonComponent
              checked={(cell.row.original as ParserSelection).isDevelopmentVersion}
              setChecked={(value) => setValue((cell.row.original as ParserSelection).id, value)}
            >
              <></>
            </ToggleButtonComponent>
          </div>
        ),
      },
      {
        Header: "Format ID",
        accessor: "id",
      },
      {
        Header: "Format",
        accessor: "name",
      },
      {
        Header: "Development state",
        accessor: "developmentState",
      },
    ],
    [setValue]
  );

  return (
    <div>
      <TableView>
        <TableView.Head>
          <TableView.Head.Label>Dataset Maintenance</TableView.Head.Label>
        </TableView.Head>
        <TableView.Body>
          <TableView.Body.Content>
            <Table columns={columns} data={parsers || []} setSelection={selectionSetter} />
          </TableView.Body.Content>
        </TableView.Body>
      </TableView>
    </div>
  );
};

import { FileExcludePattern } from "./DataSource";
import { Format } from "./Format";
import {
  IGenericRequestParametersTranslator,
  INamedEntityLabels,
  IPaginationParametersTranslator,
  PropertyTranslator,
} from "./GenericTranslator";
import {
  IGenericRequestParameters,
  IEntityMinimalModel,
  IModelWithStringId,
  INamedEntity,
  IPaginationParameters,
  EntityConstants,
  DefaultOrderTypeConsts,
} from "./GenericTypes";

export const parsersConstants: EntityConstants<Parser, ParserFilters> = {
  resource: "parsers",
  frontendIndexRoute: "formats",
  entitySingular: "data format",
  entityPlural: "data formats",
  icon: "code",
};

export const ParserFieldLabels: PropertyTranslator<Parser> & {
  vendors: string;
  instruments: string;
  methods: string;
  formats: string;
} = {
  ...INamedEntityLabels,
  id: "Data format ID",
  version: "Version",
  exec: "Execution command",
  interpreter: "Interpreter",
  keepParentFolders: "Keep parent folders",
  wholeFolder: "Whole folder",
  patterns: "Patterns",
  metaData: "Metadata",
  majorVersion: "Major version",
  minorVersion: "Minor version",
  hasVisualization: "Has visualization",
  isCustom: "Is custom",
  isBeta: "Is beta",
  vendors: "Vendors",
  instruments: "Instruments",
  methods: "Methods",
  formats: "File formats",
  fileExcludePatterns: "Excluded files",
} as const;

export interface ParserFingerprints {
  [pattern: string]: FileFingerprint;
}
export interface FileFingerprint {
  forced_rules?: {
    force_fingerprint?: boolean;
    force_additional_filepattern?: boolean;
  };
  fingerprints?: Fingerprint[];
}
export interface Fingerprint {
  string: string;
  encoding: "binary" | "ascii";
  type: "magicbyte" | "line";
  count?: number;
  offset?: number;
}
export interface ParserMetadata {
  vendor: ParserVendor[] | null;
  method: ParserMethod[] | null;
  format: Format[] | null;
  instrument: ParserInstrument[] | null;
}
export interface Pattern {
  re: string;
  template: string;
  required?: boolean;
  secondary?: boolean;
  file?: boolean;
  directory?: boolean;
}
export interface Parser extends IModelWithStringId, INamedEntity {
  version: string;
  exec: string;
  interpreter: string;
  keepParentFolders: number | null;
  wholeFolder: boolean;
  patterns: Pattern[] | null;
  metaData: ParserMetadata[] | null;
  majorVersion: number;
  minorVersion: number;
  fileExcludePatterns?: FileExcludePattern[];
  isCustom: boolean;
  hasVisualization: boolean;
  isBeta: boolean;
}
export interface ParserSuggestions extends Omit<IEntityMinimalModel<Parser["id"]>, "version"> {}

export interface ParserFilters extends IGenericRequestParameters<Parser>, IPaginationParameters {
  vendors?: (string | null)[] | null;
  methods?: (string | null)[] | null;
  instruments?: (string | null)[] | null;
  includeBetaParsers?: boolean | null;
  isBeta?: boolean | null;
  isCustom?: boolean | null;
  hasVisualization?: boolean | null;
}
export const ParserFiltersTranslator: PropertyTranslator<ParserFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
  vendors: "Vendors",
  methods: "Methods",
  instruments: "Instruments",
  includeBetaParsers: "Include parsers in development",
  isBeta: "State",
  isCustom: "Is custom format",
  hasVisualization: "Has visualization",
} as const;

export const ParserSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;
export interface Parsers {
  results: Parser[];
}

export const parserMethodsConstants: EntityConstants<ParserMethod, ParserMethodFilters> = {
  resource: "parser_methods",
  frontendIndexRoute: "formats",
  entitySingular: "method",
  entityPlural: "methods",
  icon: "code",
};

export interface ParsersMethods {
  [id: string]: {
    id: string;
    name: string;
    fullName: string;
    from: string[] | null;
    description: string | null;
  };
}

// New endpoints
export interface ParserMethod extends IModelWithStringId, INamedEntity {
  fullName: string;
  from: IEntityMinimalModel<string>[];
  description: string;
  parent: IEntityMinimalModel<string>;
}
export interface ParserVendor extends IModelWithStringId, INamedEntity {
  description: string | null;
  icon: string | null;
}

export const ParserMethodFieldLabels: PropertyTranslator<ParserMethod> = {
  ...INamedEntityLabels,
  description: "Description",
  fullName: "Full name",
  from: "From",
  parent: "Parent",
  id: "Data format method ID",
} as const;

export interface ParserMethodSuggestions extends IEntityMinimalModel<ParserMethod["id"]> {}

export interface ParserMethodFilters extends IGenericRequestParameters<ParserMethod>, IPaginationParameters {}

export const ParserMethodFiltersTranslator: PropertyTranslator<ParserMethodFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
} as const;

export const ParserMethodSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;
export interface Parsers {
  results: Parser[];
}

export const parserInstrumentsConstants: EntityConstants<ParserInstrument, ParserInstrumentFilters> = {
  resource: "parser_instruments",
  frontendIndexRoute: "formats",
  entitySingular: "instrument",
  entityPlural: "instruments",
  icon: "package",
};

export interface ParserInstrument extends IModelWithStringId, INamedEntity {
  description: string;
}
export const ParserInstrumentFieldLabels: PropertyTranslator<ParserInstrument> = {
  ...INamedEntityLabels,
  description: "Description",
  id: "Data format instrument ID",
} as const;

export interface ParserInstrumentSuggestions extends IEntityMinimalModel<ParserInstrument["id"]> {}

export interface ParserInstrumentFilters extends IGenericRequestParameters<ParserInstrument>, IPaginationParameters {}

export const ParserInstrumentFiltersTranslator: PropertyTranslator<ParserInstrumentFilters> = {
  ...IGenericRequestParametersTranslator,
  ...IPaginationParametersTranslator,
} as const;

export const ParserInstrumentSortingOptionsConsts = [...DefaultOrderTypeConsts] as const;

// Pattern Matching
export interface FormatFileEntryResponse extends FormatFileEntryRequest {
  isUnique: boolean;
  fragments?: FingerprintFragment[];
}
export interface FormatFileEntryRequest {
  // name: string;
  id: string;
  fullPath: string;
  fragments?: FingerprintFragment[];
}
export interface MatchRequest {
  files: FormatFileEntryRequest[];
  formatIds?: string[];
}

export interface MatchedDataset {
  id: string;
  parserId: string;
  name: string;
  parentMissing: boolean;
  files: FormatFileEntryResponse[];
  parentPath: string;
}

export interface ParserMatchResult {
  parserId: string;
  parserName: string;
  datasets: MatchedDataset[];
}

export interface FingerprintFragment {
  offset: number;
  length: number;
  bytes: string | null;
}
export interface FileFragment {
  id: string;
  fragments: FingerprintFragment[];
}
export interface MatchResult {
  matches: ParserMatchResult[];
  missingFragments: FileFragment[];
}

export type ParserDevelopmentState = "Unknown" | "ProductionOnly" | "DevelopmentOnly" | "ProductionAndDevelopment";

export interface ParserSelection {
  id: string;
  name: string;
  isDevelopmentVersion: boolean;
  developmentState: ParserDevelopmentState;
}

